// import React from "react"

// import Layout from "../components/layout"
// import Seo from "../components/seo"

// import Jet from "../images/status/status-jet.svg"
// import checkGreen from "../images/icons/full-circle-check.svg"

const Status = () => null
// const Status = () => (
//   <Layout>
//     <Seo title="Status" />
//     <section>
//       <div className="container container--status">
//         <div className="grid grid-2">
//           <div className="content">
//             <div className="card card--status">
//               <h5>
//                 Status
//                 <span>
//                   <img src={checkGreen} alt="green check"/>
//                 </span>
//               </h5>
//               <p>Scheduling is flexible and intuitive. Set it and forget it or customize things week by week. Cover or trade other’s shifts with ease. All of this and more can be done directly from the mobile apps.</p>
//             </div>
//           </div>
//           <img src={Jet} alt="Status Jet"/>
//         </div>
//       </div>
//     </section>
//   </Layout>
// )

export default Status
